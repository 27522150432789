/* Left Sidebar */
.sidebar-left {
	.submenu {
		list-style:none;
		padding-left:0;
		
		.children {
			list-style:none;
			padding-left:1.25rem;
		}
		
		a {
			color:$dark;
			text-decoration:none;
			text-transform:uppercase;
			font-family:$font-heading;
			font-weight:100;
			font-size:0.8rem;
			padding:0.8rem 0 0.5rem 0;
			display:block;
			border-bottom:1px solid rgba(0,0,0,0.15);
			
			&:hover {
				text-decoration:underline;
			}
		}
		
		li {
			position:relative;
			margin-bottom:0;
		}
		
		.ui-accordion-header-icon {
			display:block;
			width:40px;
			height:40px;
			position:absolute;
			right:0;
			top:0;
			//background:rgba(0,0,0,0.05);
		}
		.ui-icon:before {
			display:block;
			width:40px;
			height:40px;
			line-height:40px;
			text-align:center;
			font-size:0.6em;
		}
		.ui-icon-triangle-1-e:before { content: "\e900"; transform:rotate(0);}
		.ui-icon-triangle-1-s:before { content: "\e900"; transform:rotate(180deg); }
		
		.current_page_item > a, .current_page_parent > a {
			font-weight:500;
		}
	}
}

/* Right Sidebar */
.sidebar-right {
	h4 {
		text-transform:uppercase;
		font-size:1.15rem;
	}
	ul {
		padding-left:1.25rem;
		margin-bottom:1.25rem;
		position:relative;
		list-style:none;
		li {
			position:relative;
			padding-left:0;
			
			&:before {
				content:$bullet;
				font-family:'icomoon';
				position:absolute;
				left:-1.25rem;
				font-size:0.65rem;
				top:0.15rem;
				color:rgba(0,0,0,0.8);
			}	
		}
	}
}

@media screen and (max-width:750px){
	.sidebar-right {
		margin-top:2rem;
	}
}