@import "common/variables";

// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:scss
@import "../../bower_components/bootstrap/scss/bootstrap.scss";
@import "../../bower_components/slick-carousel/slick/slick.scss";
// endbower

@import "../../bower_components/slick-carousel/slick/slick-theme.scss";
@import "common/global";
@import "components/buttons";
@import "components/comments";
@import "components/forms";
@import "components/grid";
@import "components/wp-classes";
@import "layouts/header";
@import "layouts/sidebar";
@import "layouts/footer";
@import "layouts/pages";
@import "layouts/posts";
@import "layouts/directory";
@import "layouts/tinymce";
@import "http://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css";