// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$header-background:		#212121;
$brand-primary:         #3B8686;
$white: 				#ffffff;
$white-tint: 			rgba(255,255,255,0.1);
$black-tint: 			rgba(0,0,0,0.3);
$dark-tint: 			rgba(33,33,33,0.8);
$dark: 					#333333;
$image-credit: 			rgba(255,255,255,0.85);
$footer-background: 	#212121;

$highlight:				#3B8686;
$body-background:		#f7f7f7;

$row-background: 		#f0f0f0;

$section-header:		#333333;

// Fonts
$font-heading: 'Hind', sans-serif;
$font-body: 'Gentium Basic', serif;

$ez: ease-in-out;

$bullet: "\e901";

$spacing: 0.15rem;

$slick-font-path: "../fonts/";