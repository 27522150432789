@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?9lunuj');
  src:  url('../fonts/icomoon.eot?9lunuj#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?9lunuj') format('truetype'),
    url('../fonts/icomoon.woff?9lunuj') format('woff'),
    url('../fonts/icomoon.svg?9lunuj#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="ico-"], [class*=" ico-"], .ui-icon {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ico-google-plus:before {content: "\e910";}
.ico-twitter:before {content: "\e911";}
.ico-arrow-a:before {content: "\e90a";}
.ico-arrow-b:before {content: "\e90b";}
.ico-arrow-c:before {content: "\e90c";}
.ico-view:before {content: "\e90d";}
.ico-arrow-down:before {content: "\e900";}
.ico-highlight:before {content: "\e901";}
.ico-email:before {content: "\e902";}
.ico-facebook:before {content: "\e903";}
.ico-info:before {content: "\e904";}
.ico-instagram:before {content: "\e905";}
.ico-pin:before {content: "\e906";}
.ico-search:before {content: "\e907";}
.ico-map:before {content: "\e908";}
.ico-weather:before {content: "\e909";}
.ico-menu:before {content: "\e90e";}
.ico-close:before {content: "\e90f";}

body { background:$body-background; }

.wrap {
	min-height:70vh;
	border:30px solid $white;
	padding-top:3rem;
}

h1, h2, h3, h4, h5, h6, .title {
	font-family:$font-heading;
	font-weight:500;
}

h2.section-title, .main-content h2.section-title {
	font-size:1.25rem;
	position:relative;
	text-transform:uppercase;
	padding-bottom:0.25rem;
	margin-bottom:1rem;
	color:$section-header;
	
	&:after {
		content:" ";
		width:1.6rem;
		border-bottom:2px solid $section-header;
		position:absolute;
		left:0;
		bottom:0;
	}
}

h2.section-title {
	float:left;
}

.main-content h2:nth-child(1) {
	margin-top:0;
}

.see-more {
	color:$section-header;
	float:right;
	text-transform:uppercase;
	font-size:0.8rem;
	position:relative;
	top:0.18rem;
	
	i {
		margin-left:0.5rem;
	}
	
	&:hover, &:active, &:focus {
		color:$dark;
		text-decoration:none;
	}
}

ul, ol, li, p, .text, a {
	font-family:$font-heading;
	font-weight:100;
	line-height:1.4;
}

li {
	margin-bottom:0.5rem;	
}

b, strong {
	font-weight: 500;	
}

a {
	color:$highlight;
	
	&:hover {
		color:$highlight;	
	}
}

a.no-underline:hover {
	text-decoration:none;
}

blockquote {
	padding-left:1rem;
	border-left:4px solid rgba(0,0,0,0.25);
	font-style:italic;
	font-size:1rem;
}

table {
	width:100%;
	
	td {
		padding:0.75rem 0.5rem;	
	}
	
	thead {
		background:rgba(0,0,0,0.08);
		text-transform:uppercase;
		font-size:0.8rem;
		
		tr {
			border-bottom:1px solid rgba(0,0,0,0.5);
		}
		
		td {
			vertical-align:bottom;
			font-size:0.7rem;
		}
	}
	
	tbody {
		tr {
			
			&:nth-child(2n) {
				background:rgba(0,0,0,0.04);	
			}
		}
		
		td {
			font-size:0.9rem;
		}
	}
}

.row.background {
	background:$row-background;
}
.page-break {
	border-top:3px solid $white;
}
.minus-margin-bottom { margin-bottom:-3em; }
.minus-margin-top { margin-top:-3rem; }
.no-padding-top {padding-top:0 !important;}
.no-padding-bottom {padding-bottom:0 !important;}

.breakout {
	padding:3rem 0;
	text-align:center;
}

.image-credit {
	position:absolute;
	right:1rem;
	bottom:0.5rem;	
	
	.credit {
		color:$image-credit;
		text-transform:uppercase;
		font-size:0.7em;
	}
}

#scroll-target {
	display:block;
	height:1px;
	max-height:1px;	
}

.home .home-content {
	padding-top:5rem;
}

#breadcrumbs{
    list-style:none;
    overflow:hidden;
	padding:0;
	margin:0 0 2rem 0;
	
	li {
		display:inline-block;
		vertical-align:middle;
		margin-right:0.5rem;
	}
	
	.separator{
		font-size:1.125rem;
		font-weight:100;
		color:#ccc;
	}
	
	li, a {
		font-family:$font-heading;
		font-size:0.6rem;
		text-transform:uppercase;
		color:$dark;
		font-weight:100;
		
		&:hover {
		}
	}
}

.single #breadcrumbs, .directory-view #breadcrumbs {
	margin:0;
}
.single .main-content h1.entry-title {
	margin-bottom:1rem;
	font-size:2.2rem;
	
}
.nav-links {
	> div {
		display:inline-block;
	}
	.nav-next {
		float:right;
	}
	.nav-previous {
		float:left;
	}
}

.post-nav {
	float:left;
	width:100%;
	margin-top:2rem;	
}
.left { float:left; }
.right { float:right; }

.readmore, .load-more, .button, button, .filter-bar button {
	font-family:$font-heading;
	border:none;
	background:$white;
	color:$dark;
	font-size:0.8rem;
	text-transform:uppercase;
	padding:0.6rem 1rem;
	display:block;
	cursor:pointer;
	transition:background 0.24s $ez;
	border-radius:0.15rem;
	line-height:1;
	
	&:hover, &:focus, &:active, &.active {
		outline:none;
		background:$highlight;
		color:$white;
		text-decoration:none;
	}
}

.readmore {
	background:#eee;
	margin-bottom:20px;
	display:inline-block;
}

.load-more {
	margin:0 auto;
	
	&.loading {
		background-image:url('../images/loading.gif');
		background-size:contain;
		background-position:center;
		background-repeat:no-repeat;
		color:transparent;
	}
}


.content-block {
	padding:3rem 0;	
}

.featured-articles {
	padding: 3rem 0;	
}

.blog .featured-articles {
	padding-top:0;	
}

/*
	Image panels for home and landing pages.
*/
.image-panels {
	padding:3rem 0;
	
	img {
		width:100%;
		height:auto;	
	}
	
	.content-below {
		.panel {
			.panel-hero {
				height:auto;	
			}
		}

		.image-credit {
			display:none;
		}
	}
}

.image-panel {
	display:table;
	position:relative;
	background-size:cover;
	background-position:center;
	overflow:hidden;
	
	.panel-content {
		display:table-cell;
		text-align:center;
		vertical-align:middle;
		position:relative;
		text-shadow:0 0 1rem rgba(0,0,0,0.8);
	}

	h1 {
		color:$white;
		text-transform:uppercase;
		letter-spacing:$spacing;
		transition:0.6s $ez;
		transition-property:opacity, transform;
		opacity:1;
		transform:translate3d(0,0,0);
		font-size:2.6rem;
		margin-bottom:1.6rem;
	}
	
	p {
		font-family:$font-body;	
		color:$white;
		font-size:1.375rem;
		line-height:1.8;
		font-style:italic;
		transition:0.6s $ez;
		transition-property:opacity, transform;
		opacity:1;
		transform:translate3d(0,0,0);
	}
	
	.panel-link {
		position:absolute;
		left:0;right:0;
		top:0;bottom:0;
		width:100%;
		height:100%;
	}
	
	.tint {
		transition:0.6s $ez;
		transition-property:opacity;
	}
	
	&:hover {
		.tint {
			opacity:0;	
		}
		
		h1 {
			opacity:0;
			transform:translate3d(0,-2rem,0);
		}
		
		p {
			opacity:0;
			transform:translate3d(0,2rem,0);
		}
		
		
	}
	
	&.text-right, &.text-left {
		background:$white;

		h1 {
			color:$dark;
		}
		
		p {
			color:$dark;
		}
		
		.panel-content {
			text-align:left;
			text-shadow:none;
			padding:1rem;
		}
		
		&:hover {
			h1 {
				transition:none;
				opacity:1;
				transform:unset;
			}
			
			p {
				transition:none;
				opacity:1;
				transform:unset;
			}
		}
	}
	
	&.w-three {
		h1 {
			font-size:2rem;	
		}
	}
	
	&.w-two {
		h1 {
			font-size:1.3rem;
			letter-spacing:0;
		}
		
		.image-credit {
			width:100%;
			left:0;
			text-align:center;
			font-size:0.5rem;
		}
	}
	
	&.w-one {
		h1 {
			display:none;
		}
	}

	.table {display:table; margin-bottom:0; height:100%;}
	.image {background-size:cover;background-position:center;}
	.readmore {margin-top:2rem;}
}
.h-one {height:3.125rem;}
.h-two {height:6.25rem;}
.h-three {height:9.375rem;}
.h-four {height:12.5rem;}
.h-five {height:15.63rem;}
.h-six {height:18.75rem;}
.h-seven {height:21.88rem;}
.h-eight {height:25rem;}
.h-nine {height:28.13rem;}
.h-ten {height:31.25rem;}
.h-eleven {height:34.38rem;}
.h-twelve {height:37.5rem;}

.quote {
	padding:3rem 0;
	
	.quotation {
		font-family:$font-body;
		font-style:italic;
		font-size:3rem;
		font-weight:300;
	}
	
	.attribution {
		font-family:$font-heading;
		font-size:1.4rem;
		font-weight:800;
		text-transform:uppercase;
		color:rgba(0,0,0,0.3);
	}

	.quote-marks {
		font-family:$font-body;
		position:relative;
		
		&:before {
			content: "\201C";
			font-size:12rem;
			position:absolute;
			color:rgba(0,0,0,0.1);
			width:4rem;
			height:4rem;
			line-height:1;
		}
		
		&:after {
			content: "\201D";
			font-size:12rem;
			position:absolute;
			color:rgba(0,0,0,0.1);
			width:4rem;
			height:4rem;
			line-height:1;			
		}
		
	}
	
	.align-left {
		text-align:left;
		
		&.quote-marks {
			&:before {
				left:-4rem;
				top:-4rem;
			}
			&:after {
				left:-4rem;
				bottom:-4rem;
			}
		}
	}
	
	.align-center {
		text-align:center;

		&.quote-marks {
			&:before {
				left:-4rem;
				top:0;
			}
			&:after {
				right:-4rem;
				bottom:0;
			}
		}
	}
	
	.align-right {
		text-align:right;
		
		&.quote-marks {
			&:before {
				right:-4rem;
				top:-4rem;
			}
			&:after {
				right:-4rem;
				bottom:-4rem;
			}
		}		
	}
}

.popup-tint {
	background:$dark-tint;
	position:fixed;
	left:0;
	top:0;
	right:0;
	bottom:0;
	display:none;
	
	&.toggle {
		display:block;
	}
}

.popup {
	position:absolute;
	left:50%;
	top:50%;
	transform:translate3d(-50%,-50%,0);

	#mc_embed_signup {
		width:800px;
		background:$white;
		position:relative;
		display:inline-block;
			
		.mc-field-group {
			position:relative;
			
			ul {
				list-style:none;
				padding-left:0.5rem;
				width:100%;
				
				li {
					margin:0.5rem 0 0 0;	
				}
			}
		}
		
		.input-group {
			width:100%;
			display:block;
			
			strong {
				width:100%;	
				font-size:0.8rem;
				margin:0.5rem 0 0.25rem 0;				
			}
			
			label, input {
				width:auto;	
				margin:0;
			}
			
			input {
				margin-right:1rem;
			}
		}
		
		label {
			width:100%;
			font-size:0.8rem;
			margin:0.5rem 0 0.25rem 0;
		}
		
		input {
			width:100%;
			border:1px solid rgba(0,0,0,0.8);
			font-size:0.9rem;
			padding:0.25rem 0.5rem;
			
			&.mce_inline_error {
				border-width:1px;
			}
			
			&.button {
				width:auto;	
			}
		}
		
		div.mce_inline_error {
			position:absolute;
			right:0;
			bottom:0;
			font-weight:100;
			font-size:0.8rem;
			font-weight:600;
			background:rgba(180,20,0,1);
			padding:0.28rem 0.25rem;
			margin:0;
		}
	}
}

.popup-content {

	.newsletter-image {
		width:35%;
		background:rgba(0,0,0,0.2);
		float:left;
		position:absolute;
		left:0;
		right:0;
		top:0;
		bottom:0;
		overflow:hidden;
		
		img {
			height:100%;
		}
	}
	
	.newsletter-form {
		width:65%;
		float:left;
		padding:2rem;
		position:relative;
		left:35%;
	}
}

img {
	&.half-width {
		width:40%;
		
		&.alignright {
			margin:0 0 1rem 1rem;
		}
		
		&.alignleft {
			margin:0 1rem 1rem 0;	
		}
	}
}

article img {
	margin:1rem 0;	
}

/*

	INSTAGRAM

*/
.instagramfeed {
	padding:3rem 0;

	.instagram-item {
		position:relative;
		margin-bottom:40px;
		overflow:hidden;
		
		.square {
			width:100%;
			background-size:cover;
			background-position:center center;
		}

		.tint {
			opacity:0;
			transition:0.6s $ez;
			transition-property:opacity;
			background:rgba(0,0,0,0.6);
		}

		img {
			width:100%;
			height:auto;
			transform:scale(1);
			transition:transform 0.6s $ez;
		}

		&:hover {
			img {
				transform:scale(1.02);
			}
		}
		
		.caption {
			position:absolute;
			left:0;
			bottom:0;
			padding:1rem;
			margin:0;
			opacity:0;
			transform:translate3d(0,4rem,0);
			transition:0.6s $ez;
			transition-property:opacity,transform;
			color:$white;
		}
		
		a {
			position:absolute;
			left:0;right:0;
			top:0;bottom:0;
			width:100%;
			height:100%;
		}
		
		&:hover {
			.tint {
				opacity:1;
			}
			
			p {
				opacity:1;
				transform:translate3d(0,0,0);
			}
		}
	}

	.follow-instagram {
		text-align:center;
	}
}

.close {
	position:absolute;
	right:0;
	top:0;
	font-size:1.25rem;
	padding:0.5rem;
}


.slick-slide {
    img {
        width:100%;
        height:auto;
    }
}
.publication-slider-content {
    .button {
        border:1px solid #222222;
        color:#222222;
        background:#fafafa;
        padding:1rem 3rem;
        transition:all 0.6s ease;
        text-transform:uppercase;
        text-decoration:none;
        display:inline-block;
        font-size:1rem;
        line-height:1;

        &:hover {
            color:#fafafa;
            background-color:#222222;
        }
    }
}

@media screen and (max-width:1024px){
	.wrap {
		border-width:15px;	
	}
	.image-panel {
		h1 {
			font-size:2.1rem;	
		}
	}
}

@media screen and (max-width:768px){
	.popup {
		#mc_embed_signup {
			width:600px;
		}
	}

	.popup-content {
		.newsletter-image {
			position:relative;
			width:100%;
			height:100px;
			
			img {
				height:auto;
				width:100%;	
			}
		}
		
		.newsletter-form {
			width:100%;
			left:0;
		}
	}

	#newsletter-email {
		text-align:center;	
	}

	footer {
	
		h3 {
			margin-top:2rem;
		}
		
		.footer-logo {
			.brand {
				margin-bottom:0;
			}
		}
		
		#newsletter-email {
			text-align:center;
		}
		
		#newsletter-subscribe {
			display:inline-block;	
		}
	}
	
	.image-panel {
		&.text-left, &.text-right {
			.r-height {
				height:auto;	
			}
			.panel-content {
				padding:3rem 0;
				text-align:center;
			}
		}
	}
}

@media screen and (max-width:576px){
	.wrap {
		border-width:10px;
	}

	.quote {
		.quotation {
			font-size:2rem;
		}
	}
	
	.image { height:400px; }
	
	.instagramfeed {
		.instagram-item {
			.caption {
				transform:translate3d(0,0,0);
				opacity:1;
				background:rgba(0,0,0,0.6);
			}
		}
	}

	img {
		&.half-width {
			width:100%;
			
			&.alignright { margin:1rem 0; }
			&.alignleft { margin:1rem 0; }
		}
	}

	footer {
		.footer-logo .brand {
			height: auto;
			
			svg {
				width:100%;
				height:auto;
			}
		}
	}
	
	.popup {
		.newsletter-image {
			display:none;
		}

		#mc_embed_signup {
			width:340px;
			
			label {
				margin:0;
			}
			input {
				padding:0.1rem 0.5rem;	
			}
			h4 {
				font-size:1.2rem;
			}
			p {
				font-size:0.8rem;
				line-height:1.2;
			}
			
			div.mce_inline_error {
				font-size:0.6rem;
			}
		}
	}
}

@media screen and (max-width:320px){
	.popup {
		#mc_embed_signup {
			width:320px;
		}
	}
}