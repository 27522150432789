article {
	img {
		width:100%;
		height:auto;
	}
}

.entry-title {
	a {
		line-height:1.2;	
	}
}