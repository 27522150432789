

footer {
	background:$footer-background;	
	padding-top:40px;

	color:$white;

	.footer-logo {
		
	}
	
	.brand {
		display:block;
		margin-bottom:1rem;	
		width:100%;
		height:auto;
		
		svg {
			height:auto;
			width:100%;
			
			path {
				fill: $white;	
			}
		}
		
	}

	.footer-content {
		padding:2rem 0 5rem;
	}

	h3 {
		font-size:1rem;
		margin-bottom:1rem;
		text-transform:uppercase;
		letter-spacing:$spacing;
		position:relative;
		padding-bottom:0.25rem;
		
		&:after {
			content:" ";
			width:1.6rem;
			border-bottom:2px solid $white;
			position:absolute;
			left:0;
			bottom:0;
		}
	}

	[class^="ico-"] {
		font-size:2rem;
		margin-right:0.6rem;
		opacity:0.8;
		
		&:hover {
			opacity:1;
		}
	}
	
	a {
		color:$white;
		
		&:hover, &:active, &:focus {
			color:$white;
		}
	}
	
	p {
		margin-bottom:2rem;
		font-size:0.95rem;
	}
}

.copyright {
	text-align:center;
	color:$white;
}

#newsletter-email {
	width:100%;
	border:none;
	padding:0.25rem 0.5rem;
	margin-bottom:0.5rem;
}

.footer-nav {
	list-style:none;
	padding:0;	
	
	a {
		color:$white;
		font-size:0.95rem;
	}
}

/* page curl */
.content figure.mag-cover {
	box-shadow:0 0 20px #ddd;
}
figure.mag-cover {
  color: #fff;
  position: relative;
  float: left;
  overflow: hidden;
  margin: 10px 1%;
  min-width: 100px;
  max-width: 100%;
  width: 100%;
  background: transparent;
  text-align: center;
}

figure.mag-cover * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

figure.mag-cover img {
  opacity: 1;
  width: 100%;
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s;
}

figure.mag-cover > div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

figure.mag-cover > div::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
  opacity: 0;
  -webkit-transition: opacity 0.4s;
  transition: opacity 0.4s;
  background-image: linear-gradient(-45deg, #000000 0%, transparent 40%, rgba(255, 255, 255, 0.15));
}

figure.mag-cover i {
  display: inline-block;
  font-size: 26px;
  color: #ffffff;
  padding: 6px 0px;
  position: absolute;
  bottom: 5px;
  right: -4px;
  opacity: 0;
  z-index: 1;
  -webkit-transition: 0.05s linear;
  transition: 0.05s linear;
  -webkit-transition-delay: 0.01s;
  transition-delay: 0.01s;
}

figure.mag-cover .curl {
  width: 0px;
  height: 0px;
  position: absolute;
  bottom: 0;
  right: 0;
  background: linear-gradient(135deg, #ffffff, #f3f3f3 20%, #bbbbbb 38%, #aaaaaa 44%, #888888 50%, rgba(0, 0, 0, 0.7) 50%, rgba(0, 0, 0, 0.4) 60%, rgba(0, 0, 0, 0.3));
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  transition: all .4s ease;
}

figure.mag-cover .curl:before,
figure.mag-cover .curl:after {
  content: '';
  position: absolute;
  z-index: -1;
  left: 12%;
  bottom: 6%;
  width: 70%;
  max-width: 300px;
  max-height: 100px;
  height: 55%;
  box-shadow: 0 12px 15px rgba(0, 0, 0, 0.3);
  transform: skew(-10deg) rotate(-6deg);
}

figure.mag-cover .curl:after {
  left: auto;
  right: 6%;
  bottom: auto;
  top: 14%;
  transform: skew(-15deg) rotate(-84deg);
}

figure.mag-cover a {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  color: #ffffff;
  z-index: 1000;
}

figure.mag-cover:hover > div::before {
  opacity: 1;
}

figure.mag-cover:hover i {
  opacity: 0.7;
  -webkit-transition-delay: 0.15s;
  transition-delay: 0.15s;
}

figure.mag-cover:hover .curl {
  width: 90px;
  height: 90px;
}
.popup-tint{z-index:2000;}
.directory-details{float:left;}
.directory-details dt {width:30%;float:left;font-weight:400;text-transform:uppercase;font-size:0.85rem;padding-top:0.2rem;}
.directory-details dd {width:70%;float:left;font-weight:100;}
a.readmore-panel{position:absolute;left:0;top:0;right:0;bottom:0;}
.panel .readmore {
	z-index:1000;	
}

@media screen and (max-width:700px){
	.directory-details dt {width:100%;}
	.directory-details dd {width:100%;}
}

@media screen and (max-width:768px){
	footer {
		h3 {
			display:inline-block;
		}
		
		.brand {

		}
	}
}
