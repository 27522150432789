/* 

	Site Banner

*/
.banner {
	background:$header-background;
	z-index:100;
	height:8vh;
	position:relative;
	transition:height 0.6s $ez;
	
	&.tall {
		height:30vh;
	}
}

.logo-container {
	height:18px;
	transition:all 0.6s $ez;
	margin:3vh 0 0 3vw;
	float:left;
	
	a {
		height:100%;
		width:auto;
		display:block;
	}
	
	svg {
		height:100%;
		width:auto;
		path {
			fill: $white;
		}
	}
}

.banner-text {
	position:absolute;
	left:3vw;
	bottom:2vh;
	width:60vw;
	
	h1 {
		font-size:0.9rem;
		color:$white;
		text-transform:uppercase;
		letter-spacing:$spacing;
		margin:0 0 0.5rem;
		padding:0 0 0.3rem;
		position:relative;
		
		&:after {
			content:" ";
			width:1.6rem;
			border-bottom:2px solid $white;
			position:absolute;
			left:0;
			bottom:0;
		}
	}
	
	h2 {
		font-family:$font-body;
		font-style:italic;
		font-size:4.4rem;
		color:$white;
		line-height:1;
		margin:0;
	}
}

/*

	Site Navigation 

*/
.nav-container {
	/*display:flex;
	flex-grow:1;
	align-items:center;
	justify-content:right;*/
	float:right;
	margin:2vh 3vw 2vh 3vw;
	position:relative;
	z-index:1000;
}

.nav-toggle {
	position:absolute;
	right:0;
	top:0;
	margin:0;
	right:3vw;
	top:2vh;
}

.ubermenu-content-block {
	a {
		font-size:1.6rem;
		color:$white;
		opacity: 1;
		transition:opacity 0.2s ease-in-out;
		line-height:1;
		
		&:hover {
			opacity:0.8;
			text-decoration:none;
		}
	}
}

.ubermenu-skin-black-white-2.ubermenu-responsive-toggle {
	border:none;
	box-shadow:none;
	text-align:center;
	padding:0;
	
	.ico-menu {
		font-size:1.4rem;
		line-height:1.6rem;
		color:$white;
		margin:0;
	}
}

.ubermenu .ubermenu-custom-content-padded {
	padding:8px 15px;
}

.ubermenu-skin-black-white-2 .ubermenu-submenu.ubermenu-submenu-drop {
	background:$dark;
	border:none;
}

/*

	Page Hero, common.

*/

.hero {
	&.tall {
		min-height:93vh;
		height:93vh;
	}
	
	&.medium {
		min-height:70vh;
		height:70vh;
	}
	
	&.short {
		min-height:20vh;
		height:20vh;
	}
}

.hero {
	position:relative;
	background-size:cover;
	background-attachment:fixed;
	background-position-x:50%;
	transition:0s linear;
	transition-property:background-position-y;
	width:100%;
	
	.hero-tint {
		position:absolute;
		left:0;
		top:0;
		right:0;
		bottom:0;
		width:100%;
		height:100%;
		background:$black-tint;
	}
	
	.hero-overlay {
		display:table;
		width:100%;
		height:100%;
		position:absolute;
		
		.hero-overlay-content {
			display:table-cell;
			text-align:center;
			vertical-align:middle;
			
			color:$white;
			
			h1,h2 {
				margin:0;
				line-height:1;
			}
			
			.hero-title {
				font-family:$font-heading;
				font-weight:600;
				text-transform:uppercase;	
				letter-spacing:0.1em;
				font-size:4.2rem;
				text-shadow:0 0 1rem rgba(0,0,0,0.4);
				width:90vw;
				margin:0 auto;
			}
			
			.hero-subtitle {
				font-family:$font-body;
				font-weight:400;
				font-style:italic;	
				font-size:1.6rem;
				width:50vw;
				margin:0 auto;
				line-height:1.5;
				text-shadow:0 0 1rem rgba(0,0,0,0.4);
			}
		}
	}
	
	.info-overlay {
		position:absolute;
		right:0;
		bottom:1rem;
		background:rgba(0,0,0,0.8);
		padding:1.4rem 5rem 1.4rem 1.4rem;
		box-shadow:0 0 1rem rgba(0,0,0,0.8);
		color:$white;
		
		.info-overlay-content {
			
			p {
				margin-bottom:0;
			}
			
			.info-overlay-caption {
				font-size:1.1rem;
				font-weight:500;
			}
			
			.info-overlay-credit {
				font-size:0.6rem;
				text-transform:uppercase;
			}
		}
	}
}

/* scroll down */
$scroll-down-size: 64px;

.scroll-down {
	position:absolute;
	bottom:0;
	left:50%;
	transform:translateX(-50%);
	width:$scroll-down-size;
	height:$scroll-down-size;
	background:transparent;
	border:none;
	cursor:pointer;
	text-align:center;
	outline:none;
	
	&:focus {
		outline:none;
	}
	
	i {
		line-height:$scroll-down-size;
		color:$white;
		font-size:18px;
		animation: scroll-indicator 0.8s infinite alternate;
		display:block;
	}
}

.mouse {
	width:23px;
	height:38px;
	border:3px solid white;
	border-radius:16px;
	position:relative;
	display:inline-block;
}

.mouse:after {
	content:" ";
	position:absolute;
	width:5px;
	height:5px;
	border-radius:5px;
	background:white;
	left:6px;
	top:5px;
	animation: mouse-scroll 0.8s infinite alternate ease-in-out;
}

@keyframes mouse-scroll {
	0%{top:5px;}
	100%{top:9px;}
}

@keyframes scroll-indicator {
	0% { transform:translateY(-8px); }
	100% { transform:translateY(8px); }
}

@media screen and (max-width:1024px){
	.nav-container {
		margin:2vh 0;
		background:rgb(33,33,33);
		position:absolute;
		right:0;
		top:6vh;
		width:100vw;
	}
	
	.banner {
		.banner-text {
			width:94vw;
			
			h2 {
				font-size:3.6rem;
			}
		}
	}	
}

@media screen and (max-width:768px){
	.banner {
		.banner-text {
			h2 {
				font-size:3.8rem;	
			}
		}
	}
}

@media screen and (max-width:736px){
	.banner.tall {
		height:8vh;
		
		.banner-text {
			display:none;
		}
	}
}

@media screen and (max-width:576px){
	.scroll-down {display:none;}

	.banner.tall {
		height:8vh;
		
		.banner-text {
			display:none;
		}
	}
	
	.logo-container {
		height:16px;
		margin:28px 20px;
		
		a, svg {
			text-align:left;
			width:auto;
			height:100%;
		}
	}

}

@media screen and (max-width:500px){
	.logo-container {
		height:14px;
		margin:20px 20px;
	
	}
}