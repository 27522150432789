.introduction {
	h1 {
		font-size:58px;
		text-transform:uppercase;
		letter-spacing:$spacing;
	}
	
	p {
		font-style:italic;
		font-size:26px;
		line-height:46px;
		
		strong {
			font-weight:600;	
		}
	}
}

.introduction {
	.main-content {
		h1 {
			font-size:3rem;
			text-transform:uppercase;
			letter-spacing:$spacing;
		}
		
		p {
			font-style:italic;
			font-size:26px;
			line-height:46px;
			font-family:$font-body;
			
			strong {
				font-weight:600;	
			}
		}
	}
}

.home.page {
	.introduction p, .introduction a {
		font-family:$font-body;
	}
}

.tint {
	position:absolute;
	left:0;right:0;
	top:0;bottom:0;
	width:100%;
	height:100%;
	background:rgba(0,0,0,0.4);
}

.icon-panels {
	padding:3rem $grid-gutter-width-base/2 3rem;
	
	.icon-panel {

	}
	
	.panel-content {
		background:$white;
		padding:2rem 4rem;
		text-align:center;
		
		.icon {
			font-size:50px;
			margin:20px;
		}
		
		h1 {
			font-size:20px;
			font-weight:600;
			letter-spacing:$spacing;
			margin-bottom:30px;
		}
		
		p {
			font-size:18px;
			font-style:italic;	
			margin-bottom:30px;
			font-family:$font-body;
		}
	}
}

.intro-content {
	h1 {
		font-size:3rem;
		letter-spacing:0.25rem;
		text-transfrom:uppercase;
	}

	h1.page-title {
		text-transform:uppercase;
	}

	p.page-subtitle {
		font-family:$font-body;
		font-size:1.3rem;
		font-style:italic;
		line-height:1.4;
		margin-bottom:1.2rem;
	}
}

.main-content {
	padding-bottom:3rem;

	h1 {
		font-size:3rem;
		letter-spacing:0.25rem;
		text-transfrom:uppercase;
	}

	h1.page-title {
		text-transform:uppercase;
	}
	
	p.page-subtitle {
		font-family:$font-body;	
		font-size:1.3rem;
		font-style:italic;
		line-height:1.4;
		margin-bottom:1.2rem;
	}

	h2 {
		font-size:1.8rem;
		margin:2.25rem 0 0.75rem 0;
		line-height:1;
		letter-spacing:0.1rem;
	}
	
	h3 {
		font-size:1.3rem;
		margin:2.25rem 0 0.5rem 0;
		line-height:1;
		letter-spacing:0.1rem;
	}
	
	h4 {
		font-size:1rem;
		letter-spacing:0.1rem;
	}
	
	p {
		margin-bottom:0.75rem;
	}
	
	a {
	}
	
	ul {
		padding-left:1.5rem;	
		
		li {
			padding-left:0.25rem;
		}
	}
}

@media screen and (max-width:1024px){
	.icon-panels {
		padding:3rem $grid-gutter-width-base/2 3rem;
		
		.icon-panel {
			margin-bottom:30px;
		}	
	}
}

@media screen and (max-width:576px){
	.introduction {
		h1 {
			font-size:2.4rem;
		}
		
		p {
			font-size:1.4rem;
			line-height:1.4;
		}
	}
}